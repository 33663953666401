import React from 'react'
import './src/components/tailwind.css'

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://player.vimeo.com/api/player.js')
  }
}
